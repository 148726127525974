import React from 'react';
// eslint-disable-next-line import/named
import {NextSeo, NextSeoProps, ProductJsonLd} from 'next-seo';
import {
    PROJECT_ENVS,
    SERVICES,
    PROJECT_NAME,
    RATING_STATS_FONOMA,
    RATING_STATS_TELOA,
} from '../../modules/util/constants';
import {getLanguageAwareURL} from '../../modules/util/utilities/get-language-aware-url';
import {getLanguageAlternatesBase} from '../../modules/util/seo/get-alternative-langs-href';
import useTranslation from 'next-translate/useTranslation';

interface HomeLandingSeoProps extends NextSeoProps {
    title: string;
    serviceName: string;
    description: string;
    lang: string;
    ogTitle: string;
    ogDescription: string;
}

/**
 * This component contains the SEO configuration for home landing
 */
export const HomeLandingSeo = (props: HomeLandingSeoProps): JSX.Element => {
    const {title, description, ogTitle, lang, serviceName, ogDescription} =
        props;

    const {t} = useTranslation();
    const robotsContent =
        process.env.NEXT_PUBLIC_PROJECT_ENV === PROJECT_ENVS.STAGE
            ? 'noindex, nofollow, noarchive'
            : 'index, follow';
    const baseURL = process.env.NEXT_PUBLIC_ASSETS_URL.replace('/assets', '');
    const canonicalPath = serviceName === SERVICES.INTERNET ? '/internet' : '/';
    const canonicalUrl = getLanguageAwareURL(canonicalPath, lang);

    let productName = '';
    let ratingValue = '';
    let reviewCount = '';

    if (
        (process.env.NEXT_PUBLIC_PROJECT_NAME as string) === PROJECT_NAME.FONOMA
    ) {
        ratingValue = RATING_STATS_FONOMA.RATING_VALUE;
        reviewCount = RATING_STATS_FONOMA.REVIEW_COUNT;
        productName = t('home_landing:snippet-product-name-fonoma');
    } else {
        ratingValue = RATING_STATS_TELOA.RATING_VALUE;
        reviewCount = RATING_STATS_TELOA.REVIEW_COUNT;
        productName = t('home_landing:snippet-product-name-teloa');
    }

    return (
        <>
            <NextSeo
                title={title}
                description={description}
                canonical={canonicalUrl}
                openGraph={{
                    title: ogTitle,
                    url: canonicalUrl,
                    images: [
                        {
                            url: `${baseURL}/home/v2/facebook-opengraph.png`,
                        },
                    ],
                    description: ogDescription,
                }}
                languageAlternates={getLanguageAlternatesBase(canonicalPath)}
                additionalMetaTags={[
                    {
                        name: 'robots',
                        content: robotsContent,
                    },
                ]}
            />
            <ProductJsonLd
                productName={productName}
                description={description}
                url={canonicalUrl}
                aggregateRating={{
                    ratingValue: ratingValue,
                    reviewCount: reviewCount,
                    bestRating: '5',
                    worstRating: '1',
                }}
            />
        </>
    );
};
