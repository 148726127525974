import {LANGUAGES} from './constants';

/**
 * Retrieves the promo URL based on the given language.
 *
 * @param {string} lang - The language code ('es' for Spanish, 'en' for English).
 * @returns {string} The blog URL for the specified language.
 * @throws {Error} If an unsupported language is provided.
 */
export function getBlogUrl(lang: string): string {
    switch (lang) {
        case LANGUAGES.SPANISH:
            return process.env.NEXT_PUBLIC_BLOG_URL_ES;
        case LANGUAGES.ENGLISH:
            return process.env.NEXT_PUBLIC_BLOG_URL_EN;
        default:
            throw new Error(`Unsupported language: ${lang}`);
    }
}
