import React from 'react';
// eslint-disable-next-line import/named
import {NextSeo, NextSeoProps, ProductJsonLd} from 'next-seo';
import {SEOPropsMerger} from '../../modules/util/seo/seo-props-merger';
import useTranslation from 'next-translate/useTranslation';
import {PROMO_PATH_MAP} from '../../modules/util/seo/promo-path-map';
import {getLanguageAlternates} from '../../modules/util/seo/get-alternative-langs-href';
import {getLanguageAwareURL} from '../../modules/util/utilities/get-language-aware-url';
import {
    RATING_STATS_FONOMA,
    RATING_STATS_TELOA,
    PROJECT_NAME,
} from '../../modules/util/constants';
import Head from 'next/head';

export interface FonomaSeoProps extends NextSeoProps {
    operator: string;
    isAcquisition: boolean;
}

interface CommonNextSeoProps extends NextSeoProps {
    t: (str: string) => string;
    lang: string;
    operator: string;
    isAcquisition: boolean;
}

export function CommonNextSeo({
    t,
    lang,
    operator,
    isAcquisition,
    ...rest
}: CommonNextSeoProps) {
    const nextSeoProps = {
        ...SEOPropsMerger(rest, t),
        canonical: getLanguageAwareURL(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            PROMO_PATH_MAP[operator][isAcquisition],
            lang
        ),
        languageAlternates: getLanguageAlternates(operator, isAcquisition),
    };

    return <NextSeo {...nextSeoProps} />;
}

/**
 * SEO component that, if not passed in, includes our defaults for title, description, og-title and og-description.
 *
 * NOTE: The translation function is passed to SEOPropsMerger to avoid violating the rule of hooks
 * @param props
 * @constructor
 */
export const FonomaSEO = (props: FonomaSeoProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const {t, lang} = useTranslation();
    const {operator, isAcquisition} = props;
    let ratingValue = '';
    let reviewCount = '';
    let productName = '';

    if (
        (process.env.NEXT_PUBLIC_PROJECT_NAME as string) === PROJECT_NAME.FONOMA
    ) {
        ratingValue = RATING_STATS_FONOMA.RATING_VALUE;
        reviewCount = RATING_STATS_FONOMA.REVIEW_COUNT;
        productName = t('landings:snippet-product-name-fonoma');
    } else {
        ratingValue = RATING_STATS_TELOA.RATING_VALUE;
        reviewCount = RATING_STATS_TELOA.REVIEW_COUNT;
        productName = t('landings:snippet-product-name-teloa');
    }

    if (isAcquisition) {
        const projectNameDisplay = process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY;

        const onlineBusinessSchema = {
            '@context': 'https://schema.org',
            '@type': 'OnlineBusiness',
            name: projectNameDisplay,
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: ratingValue,
                reviewCount: reviewCount,
                bestRating: '5',
                worstRating: '1',
            },
        };

        return (
            <>
                <CommonNextSeo
                    t={t}
                    lang={lang}
                    operator={operator}
                    isAcquisition={isAcquisition}
                    {...props}
                />
                <Head>
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(onlineBusinessSchema),
                        }}
                    />
                </Head>
            </>
        );
    }

    return (
        <>
            <CommonNextSeo
                t={t}
                lang={lang}
                operator={operator}
                isAcquisition={isAcquisition}
                {...props}
            />
            <ProductJsonLd
                productName={productName}
                aggregateRating={{
                    ratingValue: ratingValue,
                    reviewCount: reviewCount,
                    bestRating: '5',
                    worstRating: '1',
                }}
            />
        </>
    );
};

export const CanNotRenderSeo = (props: NextSeoProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const {t} = useTranslation();

    return <NextSeo {...SEOPropsMerger(props, t)} />;
};
