// eslint-disable-next-line import/no-duplicates
import {styled, withTheme} from 'styled-components';

import React from 'react';
import {mediaQueries, ThemedProps} from '../modules/common';
import withTranslation from 'next-translate/withTranslation';
import {withAnalytics} from '../modules/analytics/analytics';
import {ChangeLanguageLinkHomeFooter} from './change-language-link';
import {FooterProps} from './footer';
import {LANGUAGES} from '../modules/util/constants';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FacebookSvg from '../public/static/landings/miscellanea/facebook.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InstagramSvg from '../public/static/landings/miscellanea/instagram.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import YoutubeSvg from '../public/static/landings/miscellanea/youtube.svg';
import TiktokSvg from '../public/static/landings/miscellanea/tiktok.svg';
import XSvg from '../public/static/landings/miscellanea/x-social.svg';

import {ClickableSvg} from './clickable-svg/clickable-svg';
import {typographyMediumBodyStyles} from '../modules/common/styles';
import {Wrapper} from './home-wrapper';
import {getLanguageAwareURL} from '../modules/util/utilities/get-language-aware-url';
import Image from 'next/image';

const LinksSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({theme}): string => theme.spacing.l};

    ${mediaQueries.lessThan('small')`
        flex-direction: column;
    `}
`;

const AppsSection = styled.div``;

const BasicFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: ${({theme}) => theme.spacing.xxxxl};
    padding-bottom: ${({theme}) => theme.spacing.l};

    border-top: 1px solid ${({theme}) => theme.colors.atun};

    ${mediaQueries.lessThan('small')`
        flex-direction: column-reverse;
        
        padding-bottom: 0;   
    `}
`;

const LinksColumn = styled.div`
    display: flex;
    flex-direction: column;

    ${mediaQueries.lessThan('small')`
        margin-bottom: ${({theme}: ThemedProps): string => theme.spacing.xxl};
    `}
`;

const LinksRightColumn = styled(LinksColumn)`
    margin-left: 12vw;

    ${mediaQueries.lessThan('small')`
        margin-left: 0;
    `}
`;

const Badge = styled.a`
    height: 32px;
`;

const LeftBadge = styled(Badge)`
    margin-right: ${({theme}): string => theme.spacing.m};
`;

const Badges = styled.div`
    display: flex;
    margin-bottom: ${({theme}): string => theme.spacing.l};
`;

const Icon = styled(ClickableSvg)`
    margin-right: 30px;
`;

const Icons = styled.div`
    display: flex;
    justify-content: flex-end;

    ${mediaQueries.lessThan('small')`
        justify-content: flex-start;
    `}
`;

const BasicFooterText = styled.a`
    ${typographyMediumBodyStyles}
    text-decoration: none;
`;

const LeftBasicFooterText = styled(BasicFooterText)`
    padding-left: ${({theme}) => theme.spacing.l};
    padding-right: 0;

    ${mediaQueries.lessThan('small')`
        padding-left: 0;
        padding-right: 0;
    `}
`;

const CenterBasicFooterText = styled(BasicFooterText)`
    padding-left: ${({theme}) => theme.spacing.l};
    padding-right: ${({theme}) => theme.spacing.l};

    ${mediaQueries.lessThan('small')`
        padding-left: ${({theme}: ThemedProps): string => theme.spacing.m};
        padding-right: ${({theme}: ThemedProps): string => theme.spacing.m};
    `}
`;

const LanguageLink = styled(ChangeLanguageLinkHomeFooter)`
    ${typographyMediumBodyStyles}
    ${mediaQueries.lessThan('small')`
        text-align: left;
        padding-bottom: ${({theme}: ThemedProps): string => theme.spacing.m};
    `}
`;

const FlexDiv = styled.div`
    display: flex;

    ${mediaQueries.lessThan('small')`
        flex-direction: column-reverse;
    `}
`;

const DivLinks = styled.div`
    display: flex;

    ${mediaQueries.lessThan('small')`
        flex-direction: column;
    `}
`;

const Section = styled.div`
    ${mediaQueries.lessThan('small')`
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;    
        
        padding-bottom: ${({theme}: ThemedProps) => theme.spacing.m};
    `}
`;

const LegalSection = styled.div`
    ${mediaQueries.lessThan('small')`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        padding-bottom: ${({theme}: ThemedProps) => theme.spacing.m};
    `}
`;

const Container = styled.div`
    padding: 64px 16px 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mediaQueries.lessThan('small')`
        padding: ${({theme}: ThemedProps) => theme.spacing.xxl} ${({
            theme,
        }: ThemedProps) => theme.spacing.m} ${({theme}: ThemedProps) =>
            theme.spacing.l} ${({theme}: ThemedProps) => theme.spacing.m};
    `}
`;

export interface HomeFooterProps extends FooterProps {
    rightLinks: React.ReactNode[];
    leftLinks: React.ReactNode[];
}

const UnstyledHomeFooter = (props: HomeFooterProps): JSX.Element => {
    const {className} = props;
    const {t, lang} = props.i18n;
    const {leftLinks, rightLinks} = props;

    const mobileAppUrl = process.env.NEXT_PUBLIC_MOBILE_APP_URL;
    const PROJECT_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY;
    const START_YEAR =
        (process.env.NEXT_PUBLIC_PROJECT_NAME as string) === 'fonoma'
            ? '2014'
            : '2023';
    const emailFooterText =
        (process.env.NEXT_PUBLIC_PROJECT_NAME as string) === 'teloa' ? (
            <BasicFooterText href="mailto:help@teloa.com">
                help@teloa.com
            </BasicFooterText>
        ) : (
            ''
        );

    const termsUrl = getLanguageAwareURL('terms', lang);
    const privacyUrl = getLanguageAwareURL('privacy', lang);
    const homePageUrl = getLanguageAwareURL('/', lang);

    return (
        <div className={className}>
            <Wrapper>
                <Container>
                    <LinksSection>
                        <DivLinks>
                            <LinksColumn>
                                {leftLinks?.map(
                                    (item: React.ReactElement, index) => {
                                        return React.cloneElement(item, {
                                            key: index,
                                        });
                                    }
                                )}
                            </LinksColumn>
                            <LinksRightColumn>
                                {rightLinks?.map(
                                    (item: React.ReactElement, index) => {
                                        return React.cloneElement(item, {
                                            key: index,
                                        });
                                    }
                                )}
                            </LinksRightColumn>
                        </DivLinks>

                        <AppsSection>
                            <Badges>
                                <LeftBadge href={mobileAppUrl}>
                                    {lang === LANGUAGES.SPANISH ? (
                                        /* eslint-disable-next-line @next/next/no-img-element*/
                                        <Image
                                            alt={'AppStore badge'}
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/badges_bottoms/v2/App_Store_Badges_ES.svg`}
                                            width={99}
                                            height={32}
                                        ></Image>
                                    ) : (
                                        /* eslint-disable-next-line @next/next/no-img-element*/
                                        <Image
                                            alt={'AppStore badge'}
                                            width={99}
                                            height={32}
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/badges_bottoms/v2/App_Store_Badges_EN.svg`}
                                        />
                                    )}
                                </LeftBadge>

                                <Badge href={mobileAppUrl}>
                                    {lang === LANGUAGES.SPANISH ? (
                                        /* eslint-disable-next-line @next/next/no-img-element*/
                                        <Image
                                            alt="GooglePlay badge"
                                            width={109}
                                            height={32}
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/badges_bottoms/v2/Google_Play_Badges_ES.svg`}
                                        />
                                    ) : (
                                        /* eslint-disable-next-line @next/next/no-img-element*/
                                        <Image
                                            alt="GooglePlay badge"
                                            width={109}
                                            height={32}
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/badges_bottoms/v2/Google_Play_Badges_EN.svg`}
                                        />
                                    )}
                                </Badge>
                            </Badges>
                            <Icons>
                                <Icon
                                    href={process.env.NEXT_PUBLIC_TWITTER_URL}
                                    ariaLabel="Twitter"
                                >
                                    <XSvg />
                                </Icon>
                                <Icon
                                    href={process.env.NEXT_PUBLIC_FACEBOOK_URL}
                                    ariaLabel="Facebook"
                                >
                                    <FacebookSvg />
                                </Icon>
                                <Icon
                                    href={process.env.NEXT_PUBLIC_INSTAGRAM_URL}
                                    ariaLabel="Instagram"
                                >
                                    <InstagramSvg />
                                </Icon>
                                <Icon
                                    href={process.env.NEXT_PUBLIC_YOUTUBE_URL}
                                    ariaLabel="Youtube"
                                >
                                    <YoutubeSvg />
                                </Icon>
                                <ClickableSvg
                                    href={process.env.NEXT_PUBLIC_TIKTOK_URL}
                                    ariaLabel="TikTok"
                                >
                                    <TiktokSvg />
                                </ClickableSvg>
                            </Icons>
                        </AppsSection>
                    </LinksSection>

                    <BasicFooter>
                        <FlexDiv>
                            <Section>
                                <BasicFooterText href={homePageUrl}>
                                    {t('footer:copyright', {
                                        project: PROJECT_NAME,
                                        startYear: START_YEAR,
                                        year: new Date().getFullYear(),
                                    })}
                                </BasicFooterText>
                            </Section>
                            <LegalSection>
                                <LeftBasicFooterText href={termsUrl}>
                                    {t('footer:terms-of-use')}
                                </LeftBasicFooterText>
                                <CenterBasicFooterText href={privacyUrl}>
                                    {t('footer:privacy-policy')}
                                </CenterBasicFooterText>
                                {emailFooterText}
                            </LegalSection>
                        </FlexDiv>
                        <LanguageLink currentPath={props.currentPath} />
                    </BasicFooter>
                </Container>
            </Wrapper>
        </div>
    );
};

const StyledHomeFooter = styled(UnstyledHomeFooter)`
    background-color: ${({theme}) => theme.colors.caballa};
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const HomeFooter = withAnalytics(withTranslation(withTheme(StyledHomeFooter)));

export {HomeFooter};
