import {styled} from 'styled-components';
import React from 'react';
import {H2} from './headings';
import {mediaQueries, StyledProps, ThemedProps} from '../modules/common';
import withTranslation from 'next-translate/withTranslation';
import {I18n} from '../modules/util/i18n/i18n-interface';
import {LinkButton} from './button';
import {GradientType, HeaderNoise} from './header-noise';
import {
    typographyDisplay1Styles,
    typographyHeading2Styles,
    typographyHeading3Styles,
    typographyLargeBodyStyles,
    typographyTitleBodyStyles,
} from '../modules/common/styles';
import {Wrapper} from './home-wrapper';

export interface HomeBannerProps extends StyledProps {
    i18n: I18n;
    heading: string;
    title: string;
    subtitle?: string;
    actionText: string;
    actionLink: string;
    gradient?: GradientType;
    subtitleDisabled?: boolean;
}

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: ${({theme}) => theme.homeBanner.containerPadding};

    ${mediaQueries.lessThan('small')`
        padding: 64px 16px;
        margin-bottom: 64px;
    `}
`;

const Info = styled(H2)`
    ${typographyHeading3Styles}
    margin: 0 0 4.5rem 0;

    ${mediaQueries.lessThan('small')`         
        ${typographyTitleBodyStyles}
        line-height: 1.5;
        margin: 0 0 2.5rem 0;
    `}
`;

// This needs to be an h2 due to SEO reasons.
// See https://fonoma.slack.com/archives/C04H7BTDS/p1739537434633199
const Title = styled(H2)`
    ${typographyDisplay1Styles}
    letter-spacing: normal;
    color: ${({theme}) => theme.colors.ballena};
    margin: 0 0 ${({theme}): string => theme.spacing.xxxxl} 0;

    ${mediaQueries.lessThan('small')`         
        ${typographyHeading2Styles}
        line-height: 1.25;
        margin: 0 0 ${({theme}: ThemedProps): string => theme.spacing.m} 0;
    `}
`;

const Subtitle = styled.p`
    ${typographyLargeBodyStyles}
    letter-spacing: normal;
    color: ${({theme}) => theme.colors.ballena};
    max-width: 504px;
    text-align: center;
    margin: 0 0 ${({theme}): string => theme.spacing.xxl} 0;
`;

const StyledWrapper = styled(Wrapper)`
    max-width: 1128px;
`;

const UnstyledHomeBanner = (props: HomeBannerProps): JSX.Element => {
    const {
        className,
        heading,
        title,
        subtitle,
        actionText,
        actionLink,
        gradient,
        subtitleDisabled,
    } = props;

    const subtitleComponent = !subtitleDisabled && (
        <Subtitle>{subtitle}</Subtitle>
    );

    return (
        <div className={className}>
            {gradient && <HeaderNoise gradient={gradient} />}
            <StyledWrapper>
                <Container>
                    <Info>{heading}</Info>

                    <Title>{title}</Title>

                    {subtitleComponent}

                    <LinkButton as="a" href={actionLink}>
                        {actionText}
                    </LinkButton>
                </Container>
            </StyledWrapper>
        </div>
    );
};

const StyledHomeBanner = styled(UnstyledHomeBanner)`
    position: relative;
    background-size: auto 100%;
    background-position-x: center;

    ${mediaQueries.lessThan<HomeBannerProps>('small')`
        background-size: auto;
        background-size: cover;
    `}
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
const HomeBanner = withTranslation(StyledHomeBanner);

export {HomeBanner};
